<template>
  <div>
    <v-row class="justify-center ma-0 pa-0" v-if="isSettingsLoading">
      <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular>
    </v-row>
    <div v-else>
      <hb-form label="Record Cash Book data for Both Books" extra-large editable :active.sync="active" @save="saveAccountingSettings" @cancel="handleEmittedCancel">
        <template v-slot:tooltipBody>
          <div style="max-width: 300px;">
            If you are recording both the books (accrual and cash) for your accounting software but recording only cash internally. This is only applicable to Yardi Export
          </div>
        </template>
        <template v-slot:display>
          {{ ACCOUNTING.recordCashBookData.find(item => item.value == formData.recordCashBookData).name }}
        </template>
        <template v-slot:edit>
          <HbSelect
            v-model="formData.recordCashBookData"
            :items="ACCOUNTING.recordCashBookData"
            item-text="name"
            item-value="value"
            data-vv-scope="default"
            data-vv-name="phone"
            data-vv-as="Phone"
            :error="errors.collect('default.phone').length > 0"
            placeholder="Select Book"
          />
        </template>
      </hb-form>
      <hb-form label="Yardi Export Reference Column" extra-large editable :active.sync="active" @save="saveAccountingSettings" @cancel="handleEmittedCancel">
        <template v-slot:display>
          {{ ACCOUNTING.yardiExportRefColumn.find(item => item.value == formData.yardiExportRefColumn).name }}
        </template>
        <template v-slot:edit>
          <HbSelect
            v-model="formData.yardiExportRefColumn"
            :items="ACCOUNTING.yardiExportRefColumn"
            item-text="name"
            item-value="value"
            data-vv-scope="default"
            data-vv-name="phone"
            data-vv-as="Phone"
            :error="errors.collect('default.phone').length > 0"
            placeholder="Select Book"
          />
        </template>
      </hb-form>
      <hb-form label="Yardi Export Transaction Number Format" extra-large editable :active.sync="active" @save="saveAccountingSettings" @cancel="handleEmittedCancel">
        <template v-slot:display>
          {{ ACCOUNTING.yardiExportTransactionNoFormat.find(item => item.value == formData.yardiExportTransactionNoFormat).name }}
        </template>
        <template v-slot:edit>
          <HbSelect
            v-model="formData.yardiExportTransactionNoFormat"
            :items="ACCOUNTING.yardiExportTransactionNoFormat"
            item-text="name"
            item-value="value"
            data-vv-scope="default"
            data-vv-name="phone"
            data-vv-as="Phone"
            :error="errors.collect('default.phone').length > 0"
            placeholder="Select Book"
          />
        </template>
      </hb-form>
      <hb-form label="Yardi Financial Journal Display Type" extra-large editable :active.sync="active" @save="saveAccountingSettings" @cancel="handleEmittedCancel">
        <template v-slot:display>
          {{ formData.yardiFinancialJournalDisplayType }}
        </template>
        <template v-slot:edit>
          <hb-text-field
            v-model="formData.yardiFinancialJournalDisplayType" 
            v-validate="'required|max:255'"
            name="yardi_financial_journal_display_type"
            data-vv-as="Yardi Financial Journal Display Type"
            :error="errors.has('yardi_financial_journal_display_type')"
            id="yardi_financial_journal_display_type"
            full
          >
          </hb-text-field>
        </template>
      </hb-form>
      <hb-form label="App Folio Consolidate CC & ACH" extra-large editable :active.sync="active" @save="saveAccountingSettings" @cancel="handleEmittedCancel">
        <template v-slot:display>
          {{ ACCOUNTING.appFolioCCAndACHConsolidated.find(item => item.value == formData.appFolioCCAndACHConsolidated).name }}
        </template>
        <template v-slot:edit>
          <HbSelect
            v-model="formData.appFolioCCAndACHConsolidated"
            :items="ACCOUNTING.appFolioCCAndACHConsolidated"
            :clearable="false"
            item-text="name"
            item-value="value"
          /> 
        </template>
      </hb-form>
      <hb-form label="Separate Sage Intacct entries on Payment Methods" extra-large editable :active.sync="active" @save="saveAccountingSettings" @cancel="handleEmittedCancel">
        <template v-slot:display>
          {{ ACCOUNTING.sagePaymentMethodSeparated.find(item => item.value == formData.sagePaymentMethodSeparated).name }}
        </template>
        <template v-slot:edit>
          <HbSelect
            v-model="formData.sagePaymentMethodSeparated"
            :items="ACCOUNTING.sagePaymentMethodSeparated"
            :clearable="false"
            item-text="name"
            item-value="value"
          /> 
        </template>
      </hb-form>
      <hb-form label="Sage Intacct Journal Type" extra-large editable :active.sync="active" @save="saveAccountingSettings" @cancel="handleEmittedCancel">
        <template v-slot:display>
          {{ ACCOUNTING.sageIntacctJournalType.find(item => item.value == formData.sageIntacctJournalType).name }}
        </template>
        <template v-slot:edit>
          <HbSelect
            v-model="formData.sageIntacctJournalType"
            :items="ACCOUNTING.sageIntacctJournalType"
            :clearable="false"
            item-text="name"
            item-value="value"
          /> 
        </template>
      </hb-form>
    </div>
  </div>
</template>

<script type="text/babel">
import { ACCOUNTING } from "@/constants/accounting.js";
import { notificationMixin } from '@/mixins/notificationMixin.js';
import api from '../../../assets/api.js';
import { mapGetters, mapActions} from "vuex";
export default {
  name: "AdditionalSettings",
  mixins: [notificationMixin],
  data() {
    return {
      formData: {
        recordCashBookData: 'cash_book_only',
        yardiExportRefColumn: 'management_software',
        yardiExportTransactionNoFormat: 'unique_each_transaction',
        yardiFinancialJournalDisplayType: 'standard JE display type',
        appFolioCCAndACHConsolidated: 'yes',
        sagePaymentMethodSeparated: 'yes',
        sageIntacctJournalType: 'SLJ'
      },
      form: {},
      active: '',
      isSettingsLoading: true
    };
  },
  async created() {
    this.ACCOUNTING = ACCOUNTING;
    await this.fetchAccountingSettings();
    this.isSettingsLoading = false;
  },
  computed: {
    ...mapGetters({
      accountingSettings: "accountingStore/accountingSettings",
    }),
  },
  methods: {
    ...mapActions({
      getAccountingSettings: "accountingStore/getAccountingSettings",
    }),
    async fetchAccountingSettings() {
      try {
        if(!Object.keys(this.accountingSettings).length) {
          await this.getAccountingSettings();
        } else {
          this.formData = JSON.parse(JSON.stringify(this.accountingSettings));
        }
        this.form = Object.assign({}, this.formData);
      } catch (error) {
        this.showMessageNotification({description: error});
      }
    },
    async saveAccountingSettings() {
      await api.post(this, api.ACCOUNTING + 'settings', this.formData).then(r => {
        this.form = Object.assign({}, this.formData);
        this.active = '';
        this.showMessageNotification({type: 'success', description: "You've updated additional settings."});
      }).catch(err => {});
    },
    handleEmittedCancel() {
      this.formData = Object.assign({}, this.form);
      this.active = '';
    }
  }
}
</script>

<style scoped>
</style>
